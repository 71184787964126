.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    overflow-y: auto;
    max-height: calc(100vh - 100px); 
    /* margin-top: 1%; */
    margin-bottom:30px;
}

.info-container img {
    width: 55%;
}