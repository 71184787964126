.success-message-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.success-message {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.success-message h2 {
    margin-bottom: 20px;
    color: #4caf50;
}

.success-message button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: #4caf50;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
}

.success-message button:hover {
    background: #45a049;
}
