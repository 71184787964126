.sell-page {
    max-width: 30%;
    margin: 0 auto;
    margin-top: 2%;
    padding: 40px;
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #333;
    max-height: 80vh; /* Set a maximum height for scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    min-width:300px;
}

@media (max-width: 480px) {
    .sell-page {
        max-width: 90%; /* Adjust max-width for smaller screens */
        padding: 15px; /* Adjust padding for smaller screens */
        min-width:0px;
    }
}

form {
    display: flex;
    flex-direction: column; /* Stack form fields vertically */
    padding: 10px;
}



#submit:hover {
    background-color: #45a049; /* Button hover effect */
}


@media (max-width: 480px) {
    .sell-page {
        max-width: 90%; /* Adjust max-width for smaller screens */
        padding: 15px; /* Adjust padding for smaller screens */
    }
}

/* Keep other CSS rules unchanged */


@media (max-width: 480px) {
    .sell-page {
        max-width: 90%; /* Adjust max-width for smaller screens */
        padding: 15px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 480px) {
    .sell-page {
        max-width: 80%;
    }
}

.sell-page h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
}

.form-group select {
    -webkit-appearance: none; /* Remove default styling on WebKit browsers */
    -moz-appearance: none; /* Remove default styling on Firefox */
    appearance: none; /* Remove default styling on other browsers */
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3E%3Cpath d="M3.5 5.5L8 10l4.5-4.5z" fill="%23666"/%3E%3C/svg%3E'); /* Custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #888;
    outline: none;
    box-shadow: 0 0 5px rgba(136, 136, 136, 0.3);
}

#submit {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
}

#submit:hover {
    background-color: #45a049;
    color: #ffffff;
}

.required {
    color: #ff4d4d;
    font-weight: bold;
}
