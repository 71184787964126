.App {
  background-image: url('../public/images/KyleField.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  overflow: hidden; /* Prevent scrolling in the main container */
  /* justify-content:center; */
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms ease, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0ms ease, transform 0ms ease;
}


/* Zoom transition animations */
/* Zoom-in transition animations */
.zoom-enter {
  transform: scale(0.5); /* Start from a smaller scale */
  opacity: 0;
}
.zoom-enter-active {
  transform: scale(1); /* Gradually transition to the original scale */
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}
.zoom-exit {
  transform: scale(1); /* Maintain the original scale during exit */
  opacity: 1;
}
.zoom-exit-active {
  transform: scale(0.5); /* Zoom out to a smaller scale during exit */
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}

/* Combined slide and fade transition animations */
.slide-fade-enter {
  transform: translateX(100%);
  opacity: 0;
}
.slide-fade-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}
.slide-fade-exit {
  transform: translateX(0);
  opacity: 1;
}
.slide-fade-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}


