/* Maintenance.css */

.maintenance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f5f5f5;  /* Light grey background */
    font-family: 'Arial', sans-serif;
  }
  
  .maintenance-title {
    font-size: 2.5rem;
    color: #333;  /* Darker text color */
  }
  
  .maintenance-message {
    font-size: 1.2rem;
    color: #555;
    margin-top: 10px;
  }
  