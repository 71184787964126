.Home {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px; /* Add padding for better spacing on mobile */
}

.Home-header {
  margin-top: 40px;
}

.title {
  font-size: 85px;
  margin-top: 20px;
  color: white;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);

}

.button-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
}

.btn {
  padding: 10px 40px; /* Adjust as needed */
  font-size: 16px;
  border: 2px solid;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s; /* Add box-shadow transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Basic shadow effect */
}

.btn:hover {
  background-color: maroon;
  color: gold;
  border-color: gold;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}

.btn-buy {
  background-color: white;
  color: black;
  border-color: black;
}

.btn-sell {
  background-color: black;
  color: white;
  border-color: white;
}

/* Add CSS for the boarding pass image */
.ticket-container {
  margin-top: 200px; /* Adjust this margin to position the image vertically */
  display: flex;
  justify-content: center;
}

.ticket {
  max-width: 40%;
  height: auto;
  opacity: 0.90;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition for hover effect */
}

.ticket:hover {
  transform: translateY(-10px); /* Lift the image up by 10px */
  opacity: 1; /* Make it fully opaque on hover */
}

@media (max-width: 768px) {
  .title {
    font-size: 50px; /* Reduce font size for better fit on mobile */
  }

  .Home-header{
    margin-top:50px;
  }



  .button-container {
    margin-top:93px;
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 13px; /* Reduce gap between buttons */
    width: 100%; /* Ensure button container takes full width */
  }

  .btn {
    padding: 10px 20px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Reduce font size slightly for mobile */
    width: 100%; /* Buttons take full width on mobile */
  }

  .ticket {
    max-width: 95%; /* Increase width for better visibility on mobile */
  }
}
