.password-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dimming effect */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .password-prompt-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width:300px;
  }
  
  .password-prompt-container h2 {
    margin-bottom: 15px;
  }
  
  .password-prompt-container input {
    width: 90%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .password-prompt-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .password-prompt-buttons button {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .password-prompt-buttons button:hover {
    background-color: #d32f2f;
  }
  
  .password-prompt-buttons button:last-child {
    background-color: #ccc;
  }
  
  .password-prompt-buttons button:last-child:hover {
    background-color: #999;
  }

  /* @media (max-width:600px)
  {
      .password-prompt-container{
          width:40vw;
      }
  }

  @media (max-width:445px)
  {
      .password-prompt-container{
          width:60vw;
      }
  } */
  